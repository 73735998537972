import Brand from "./Brand";
import Category from "./Category";
import Content from "./Content";
import Media from "./Media";
import Comment from "./Comment";
import Client from "./Client";
import PackageScope from "./PackageScope";
import User from "./User";
import Timeline from "./Timeline";
import ProjectBilling from "./ProjectBilling";

export enum ProjectStatus {
    Unready = "unready",
    Confirmed = "confirmed",
    Waiting = "waiting",
    Idea = "idea",
    Shooting = "shooting",
    Draft = "draft",
    DraftSent = "draft_sent",
    Feedback = "feedback",
    DraftApproved = "draft_approved",
    Posted = "posted",
    Hold = "hold",
    Canceled = "canceled",
}

export default interface Project {
    id: number;
    number: string;
    title: string;
    date: string;
    client: Client;
    brands: Brand[];
    categories: Category[];
    package_scopes: PackageScope[];
    scope_of_work: string;
    reminder?: string;
    status: ProjectStatus;
    is_boost: boolean;
    is_asap: boolean;
    has_budget: boolean;
    is_barter: boolean;
    is_tag_brand: boolean;
    working_date: string;
    shooting_date: string;
    draft_date: string;
    post_date: string;
    working_time: string;
    shooting_time: string;
    draft_time: string;
    post_time: string;
    created_user: User;
    ae_user: User;
    shooting_user: User;
    idea_user: User;
    draft_user: User;
    contents: Content[];
    project_timelines: Timeline[];
    next_step_timeline?: Timeline;
    file_medias: Media[];
    project_comments: Comment[];
    created_at: string;
    board_index: number;
    project_billings?: ProjectBilling[];
    project_billing?: ProjectBilling;
    has_video?: boolean;
    project_value: number;
}

export class ProjectHelper {

    static isUnready(project: Project): boolean {
        return [ProjectStatus.Unready].includes(project.status)
    }

    static isConfirmed(project: Project): boolean {
        return [ProjectStatus.Confirmed].includes(project.status)
    }

    static isWaiting(project: Project): boolean {
        return [ProjectStatus.Waiting].includes(project.status)
    }

    static isIdea(project: Project): boolean {
        return [ProjectStatus.Idea].includes(project.status)
    }

    static isShooting(project: Project): boolean {
        return project.status == ProjectStatus.Shooting
    }

    static isDraft(project: Project): boolean {
        return [ProjectStatus.Draft].includes(project.status)
    }

    static isDraftSent(project: Project): boolean {
        return [ProjectStatus.DraftSent].includes(project.status)
    }

    static isFeedback(project: Project): boolean {
        return [ProjectStatus.Feedback].includes(project.status)
    }

    static isDraftApproved(project: Project): boolean {
        return project.status == ProjectStatus.DraftApproved
    }

    static isPosted(project: Project): boolean {
        return project.status == ProjectStatus.Posted
    }

    static allStatus(): string[] {
        const values = Object.values(ProjectStatus)
        return values
    }

    static statusDate(project: Project, statusKey: string): string {
        let date = project.working_date
        if (statusKey == 'shooting') {
            date = project.shooting_date
        } else if (statusKey == 'draft') {
            date = project.draft_date
        } else if (statusKey == 'post') {
            date = project.post_date
        }
        return date
    }

    static titleOfStatus(status: ProjectStatus): string {
        switch (status) {
            case ProjectStatus.Unready: {
                return "จองงานไว้นะ"
            }
            case ProjectStatus.Confirmed: {
                return "คอนเฟิร์มแล้ว"
            }
            case ProjectStatus.Waiting: {
                return "รอบรีฟ/สินค้า"
            }
            case ProjectStatus.Idea: {
                return "ทำไอเดีย"
            }
            case ProjectStatus.Shooting: {
                return "ลงพื้นที่"
            }
            case ProjectStatus.Draft: {
                return "ทำโพส"
            }
            case ProjectStatus.DraftSent: {
                return "ส่งดราฟแล้ว"
            }
            case ProjectStatus.Feedback: {
                return "ฟีดแบค"
            }
            case ProjectStatus.DraftApproved: {
                return "คอนเฟิร์มรอโพส"
            }
            case ProjectStatus.Posted: {
                return "ลงงานแล้ว"
            }
            case ProjectStatus.Hold: {
                return "ดองงาน"
            }
            case ProjectStatus.Canceled: {
                return "ยกเลิก"
            }
        }
        return "ไม่ระบุ"
    }

    static colorOfStatus(status: ProjectStatus): string {
        switch (status) {
            case ProjectStatus.Unready: {
                return "bg-white"
            }
            case ProjectStatus.Confirmed: {
                return "bg-dark"
            }
            case ProjectStatus.Waiting: {
                return "bg-primary-subtle"
            }
            case ProjectStatus.Idea: {
                return "text-bg-secondary-subtle"
            }
            case ProjectStatus.Shooting: {
                return "bg-secondary"
            }
            case ProjectStatus.Draft: {
                return "bg-info"
            }
            case ProjectStatus.DraftSent: {
                return "bg-primary"
            }
            case ProjectStatus.Feedback: {
                return "bg-warning"
            }
            case ProjectStatus.DraftApproved: {
                return "bg-success-subtle"
            }
            case ProjectStatus.Posted: {
                return "bg-success"
            }
            case ProjectStatus.Hold: {
                return "bg-default"
            }
            case ProjectStatus.Canceled: {
                return "bg-danger"
            }
        }
        return ""
    }

    static statusTitle(project: Project): string {
        return this.titleOfStatus(project.status)
    }

    static statusBadgeClass(project: Project): string {
        switch (project.status) {
            case ProjectStatus.Unready: {
                return "badge-white"
            }
            case ProjectStatus.Confirmed: {
                return "badge-dark"
            }
            case ProjectStatus.Waiting: {
                return "badge-primary-subtle"
            }
            case ProjectStatus.Idea: {
                return "badge-secondary-subtle"
            }
            case ProjectStatus.Shooting: {
                return "badge-secondary"
            }
            case ProjectStatus.Draft: {
                return "badge-info"
            }
            case ProjectStatus.DraftSent: {
                return "badge-primary"
            }
            case ProjectStatus.Feedback: {
                return "badge-warning"
            }
            case ProjectStatus.DraftApproved: {
                return "badge-success-subtle"
            }
            case ProjectStatus.Posted: {
                return "badge-success"
            }
            case ProjectStatus.Hold: {
                return "badge-default"
            }
            case ProjectStatus.Canceled: {
                return "badge-danger"
            }
        }
        return ""
    }

    static statusColorClass(project: Project): string {
        return ProjectHelper.colorOfStatus(project.status)
    }

    static statusBorderClass(project: Project): string {
        switch (project.status) {
            case ProjectStatus.Unready: {
                return "border-black"
            }
            case ProjectStatus.Confirmed: {
                return "border-dark"
            }
            case ProjectStatus.Waiting: {
                return "border-primary-subtle"
            }
            case ProjectStatus.Idea: {
                return "border-secondary-subtle"
            }
            case ProjectStatus.Shooting: {
                return "border-secondary"
            }
            case ProjectStatus.Draft: {
                return "border-info"
            }
            case ProjectStatus.DraftSent: {
                return "border-primary"
            }
            case ProjectStatus.Feedback: {
                return "border-warning"
            }
            case ProjectStatus.DraftApproved: {
                return "border-success-subtle"
            }
            case ProjectStatus.Posted: {
                return "border-success"
            }
            case ProjectStatus.Hold: {
                return "border-default"
            }
            case ProjectStatus.Canceled: {
                return "border-danger"
            }
        }
        return ""
    }

    static statusTextClass(project: Project): string {
        switch (project.status) {
            case ProjectStatus.Unready: {
                return "text-white"
            }
            case ProjectStatus.Confirmed: {
                return "text-dark"
            }
            case ProjectStatus.Waiting: {
                return "text-primary-subtle"
            }
            case ProjectStatus.Idea: {
                return "text-dark-subtle"
            }
            case ProjectStatus.Shooting: {
                return "text-secondary"
            }
            case ProjectStatus.Draft: {
                return "text-info"
            }
            case ProjectStatus.DraftSent: {
                return "text-primary"
            }
            case ProjectStatus.Feedback: {
                return "text-warning"
            }
            case ProjectStatus.DraftApproved: {
                return "text-success"
            }
            case ProjectStatus.Posted: {
                return "text-success"
            }
            case ProjectStatus.Hold: {
                return "text-default"
            }
            case ProjectStatus.Canceled: {
                return "text-danger"
            }
        }
        return ""
    }
}
