import Brand from "./Brand";
import Category from "./Category";
import ContentPlatform from "./ContentPlatform";
import Page from "./Page";
import Project from "./Project";
import User from "./User";

export default interface Content {
    id: number;
    number: string;
    shooting_user_id: number;
    draft_user_id: number;
    title: string;
    page_id: number;
    project?: Project;
    page: Page;
    platforms: string[];
    content_platforms: ContentPlatform[];
    type: string;
    date: string;
    status: ContentStatus;
    working_date: string;
    shooting_date?: string;
    draft_date?: string;
    post_date?: string;
    working_time?: string;
    shooting_time?: string;
    draft_time?: string;
    post_time?: string;
    shooting_user?: User;
    draft_user?: User;
    content_url: string;
    assets_url?: string;
    note?: string;
    is_draft_only: boolean;
    is_og: boolean;
    brands: Brand[];
    categories: Category[];
}

export enum ContentStatus {
    Shooting = "shooting",
    Draft = "draft",
    Posted = "posted",
    Waiting = "waiting",
    Canceled = "canceled",
}

export enum BadgeMode {
    Full,
    Medium,
    Small
}

export class ContentHelper {

    static allStatus(): string[] {
        const values = Object.values(ContentStatus)
        return values
    }

    static titleOfStatus(status: ContentStatus): string {
        switch (status) {
            case ContentStatus.Shooting: {
                return "ลงพื้นที่"
            }
            case ContentStatus.Draft: {
                return "ทำโพส"
            }
            case ContentStatus.Posted: {
                return "ลงงานแล้ว"
            }
            case ContentStatus.Waiting: {
                return "รอ"
            }
            case ContentStatus.Canceled: {
                return "ยกเลิก"
            }
        }
        return "ไม่ระบุ"
    }

    static statusTitle(content: Content): string {
        return this.titleOfStatus(content.status)
    }

    static typeBadgeClass(type: string): string {
        switch (type) {
            case 'post': {
                return "badge-primary"
            }
            case 'video': {
                return "badge-danger"
            }
        }
        return "outline-badge-dark"
    }

    static statusBadgeClass(content: Content): string {
        switch (content.status) {
            case ContentStatus.Shooting: {
                return "badge-secondary"
            }
            case ContentStatus.Draft: {
                return "badge-info"
            }
            case ContentStatus.Posted: {
                return "badge-success"
            }
            case ContentStatus.Waiting: {
                return "badge-white"
            }
            case ContentStatus.Canceled: {
                return "badge-danger"
            }
        }
        return ""
    }

    static statusColorClass(content: Content): string {
        switch (content.status) {
            case ContentStatus.Shooting: {
                return "text-bg-secondary"
            }
            case ContentStatus.Draft: {
                return "text-bg-info"
            }
            case ContentStatus.Posted: {
                return "text-bg-success"
            }
            case ContentStatus.Waiting: {
                return "text-bg-white"
            }
            case ContentStatus.Canceled: {
                return "text-bg-danger"
            }
        }
        return ""
    }

    static statusBorderClass(content: Content): string {
        switch (content.status) {
            case ContentStatus.Shooting: {
                return "border-secondary"
            }
            case ContentStatus.Draft: {
                return "border-info"
            }
            case ContentStatus.Posted: {
                return "border-success"
            }
            case ContentStatus.Waiting: {
                return "border-dark"
            }
            case ContentStatus.Canceled: {
                return "border-danger"
            }
        }
        return ""
    }

    static statusTextClass(content: Content): string {
        switch (content.status) {
            case ContentStatus.Shooting: {
                return "text-secondary"
            }
            case ContentStatus.Draft: {
                return "text-info"
            }
            case ContentStatus.Posted: {
                return "text-success"
            }
            case ContentStatus.Waiting: {
                return "text-white"
            }
            case ContentStatus.Canceled: {
                return "text-danger"
            }
        }
        return "text-muted"
    }

    static pageImageUrl(page?: Page): string {
        if(!page) {
            return ""
        }
        if (page.username == 'redprice50') {
            return new URL('/src/assets/images/redprice_logo.jpeg', import.meta.url).href
        } else if (page.username == 'reviewnewitem') {
            return new URL('/src/assets/images/reviewnewitem_logo.jpeg', import.meta.url).href
        } else if (page.username == 'younaidee') {
            return new URL('/src/assets/images/younaidee_logo.png', import.meta.url).href
        } else if (page.username == 'listsunday') {
            return new URL('/src/assets/images/listsunday_logo.jpeg', import.meta.url).href
        }

        return new URL('/src/assets/images/confused_whale.png', import.meta.url).href
    }

    static platformImageUrl(platform?: string): string {
        if (platform == 'facebook') {
            return new URL('/src/assets/images/facebook.svg', import.meta.url).href
        } else if (platform == 'instagram') {
            return new URL('/src/assets/images/instagram.svg', import.meta.url).href
        } else if (platform == 'tiktok') {
            return new URL('/src/assets/images/tiktok.svg', import.meta.url).href
        } else if (platform == 'blog') {
            return new URL('/src/assets/images/blog.svg', import.meta.url).href
        }

        return ""
    }
}
