<template>
    <div>
        <div class="mb-2">
            <div v-for="media in files" class="position-relative mb-2">
                <div class="d-flex align-items-center bg-light-primary p-1 rounded cursor-pointer opacity-hover "
                    @click="useOpenNewTab(media.full_url)">
                    <div class="mx-2 wh-35">
                        <i class="fs-3" v-extension-icon="media.file_name"></i>
                    </div>
                    <div class="flex-grow-1">
                        <div class="text-dark fs-09 text-break">
                            {{ media.file_name }}
                        </div>
                        <div class="text-muted d-flex align-items-center flex-wrap fs-07">
                            <div class="me-2">
                                {{ fileSize(media.size) }}
                            </div>
                            <div class="me-2">
                                Added: {{ useDateTime(media.created_at) }}
                            </div>
                            <div class="ms-auto text-decoration-underline opacity-1-hover"
                                @click.stop="askRemoveFileModal(media.file_name, media.id)">
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingOverlay :active="media.remove_loading" :width="20" :height="20" :opacity="0.7"
                    :is-full-page="false" />
            </div>
        </div>
        <FilePondComponent ref="pond" class="project" instant-upload="false" label-idle="Drop files here..."
            :allow-multiple="false" :allow-replace="true" :credits="'false'" allow-revert="false"
            :server="serverConfig" />
    </div>
</template>

<script setup lang="ts">
import { ref, computed, toRef, onMounted } from 'vue'
import Project from '@/models/Project';
import Media from '@/models/Media';
import { useDateTime, useOpenNewTab, useReplaceURLs, useJoinPaths } from '@/composables/utility';
import DialogModal from '@/components/DialogModal.vue'
import vExtensionIcon from '@/directives/extension-icon'
import { BASE_URL, getProjectFiles, projectRemoveFile } from "@/services/api/http";
import type { FilePond } from 'filepond'
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { filesize } from "filesize";
import { useCookies } from "vue3-cookies";
import { useModal } from 'vue-final-modal'
import { useToast } from 'vue-toastification';

const props = defineProps<{
    projectNumber: string
}>()

const toast = useToast()
const FilePondComponent = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
const pond = ref<FilePond | null>(null);

const projectNumber = toRef(props, 'projectNumber')
const files = ref<Media[]>([])
const fileSize = (size: number): string => {
    return filesize(size || 0, { standard: "jedec" })
}
const { cookies } = useCookies();
const xsrfToken = cookies.get('XSRF-TOKEN')
const serverConfig = {
    url: BASE_URL,
    process: {
        url: `/project/${projectNumber.value}/file/add`,
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-TOKEN': xsrfToken,
        },
        // timeout: 7000,
        onload: async (response: any) => {
            const data = await getFiles()
            files.value = data ?? []
            pond.value?.removeFiles()
        },
        // onerror: null,
        // ondata: null,
    },
}

const removingMediaId = ref<number>()
const removeFileModal = useModal({
    component: DialogModal,
    attrs: {
        onClose() {
            removeFileModal.close()
        },
        onCancel() {
            removeFileModal.close()
            console.log("cancel")
        },
        onConfirm() {
            console.log("confirm")
            requestRemoveFile()
            removeFileModal.close()
            removingMediaId.value = undefined
        },
        title: 'ต้องการลบไฟล์'
    },
    slots: {
        default: 'filename'
    }
})

const askRemoveFileModal = (fileName: string, mediaId: number) => {
    removingMediaId.value = mediaId
    removeFileModal.patchOptions({
        slots: {
            default: `<span class="text-danger">[${fileName}] จะถูกลบถาวร แน่ใจใช่หรือไม่</span>`
        }
    })
    removeFileModal.open()
    return false;
}

const requestRemoveFile = async () => {
    try {
        const mediaId = removingMediaId.value
        const number = projectNumber.value
        if (number && mediaId) {
            files.value.find(i => {
                return i.id == mediaId
            })!.remove_loading = true
            const data = await projectRemoveFile(number, mediaId)
            const medias = files.value
            const index = medias.findIndex(i => {
                return i.id == mediaId
            })
            files.value.splice(index, 1)
            toast.success(data.message, {
                timeout: 2000
            })
        }
    }
    catch (err: any) {
        console.log(err)
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {

    }
}

async function getFiles() {
    try {
        const number = projectNumber.value
        const data = await getProjectFiles(number)
        return data
    }
    catch (err: any) {

    }
    finally {

    }
}

async function init() {
    const data = await getFiles()
    files.value = data ?? []
}

onMounted(() => {
    init()
})

</script>
