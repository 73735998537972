<template>
    <div class="timeline-line">
        <div class="item-timeline justify-content-between" v-for="(timeline, index) in timelines">
            <div class="d-flex">
                <div class="t-dot">
                    <div class="t-secondary" :class="TimelineHelper.bgColorOfAction(timeline.action) "></div>
                </div>
                <div class="t-content">
                    <div class="t-uppercontent">
                        <div class="d-flex column-gap-3">
                            <div>
                                <span class="" :class="{'text-decoration-line-through': timeline.is_complete}">{{ TimelineHelper.actionTitle(timeline) }}: </span>
                                <span class="badge badge-success" v-if="timeline.user">
                                    <i class="bi bi-person-circle"></i>
                                    {{ timeline.user?.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="fs-08 text-break mb-2" v-html="useReplaceURLs(timeline.note || '') "></div>
                    <div class="fs-07 mt-1 mb-2" v-for="attachment in timeline.timeline_attachments">
                        <a class="text-primary p-1 border" v-if="attachment.media" :href="attachment.media?.full_url" target="_blank"><i class="bi bi-paperclip"></i> {{ attachment.media?.file_name }}</a>
                    </div>
                    <p>{{ useShortDate(timeline.date) }} <span v-if="timeline.time">, {{ timeline.time }}</span></p>
                </div>
            </div>
            <div>
                <div v-if="timeline.is_complete" class="avatar avatar-sm wh-25 avatar-success-subtle me-1">
                    <span class="avatar-title rounded-circle with-border"><i class="bi bi-check-lg"></i></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import Timeline, { TimelineHelper } from '@/models/Timeline';
import { getProjectTimelines } from "@/services/api/http";
import { useUserStore } from '@/stores/user';
import { useDateTime, useShortDate, useReplaceURLs } from '@/composables/utility';
import { useToast } from 'vue-toastification';

const userStore = useUserStore()
const toast = useToast()

const props = defineProps<{
    projectNumber: string
}>()

const timelines = ref<Timeline[]>([])

const loading = ref<boolean>(false)

async function fetchData() {
    try {
        const number = props.projectNumber
        const data = await getProjectTimelines(number)
        return data
    }
    catch (err: any) {

    }
    finally {

    }
}

async function init() {
    const data = await fetchData()
    timelines.value = data ?? []
}

onMounted(() => {
    init()
})

</script>
<style lang="scss">

</style>
