<template>
    <div>
        <div class="d-flex align-items-center column-gap-1">
            <div v-for="page in pages" class="avatar avatar-sm" :class="sizeClass">
                <img alt="avatar" :src="ContentHelper.pageImageUrl(page)" class="rounded-circle">
            </div>
            <div v-for="platform in platforms" class="avatar avatar-sm" :class="sizeClass">
                <img alt="avatar" :src="ContentHelper.platformImageUrl(platform)" class="rounded-circle">
            </div>
            <div v-for="t in types" class="badge badge-primary text-capitalize" :class="`${fontSizeClass} ${ContentHelper.typeBadgeClass(t)}`">{{ t }}{{ typeCountX(t) }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { BadgeMode } from '@/models/Content';
import Project from '@/models/Project';
import Page from '@/models/Page';
import { ContentHelper } from '@/models/Content';
import { unique } from '@/composables/utility';

const props = defineProps<{
    project: Project,
    size: string
}>()



const project = toRef(props, 'project')

const pages = computed( () => {
    const proj = project.value
    return unique(
        proj.contents?.map( cont => {
        return cont.page
    }), 'id')
})

const platforms = computed(() => {
    const proj = project.value
    return unique(proj.contents?.map(cont => {
        return cont.platforms
    }).flat())
})

const types = computed(() => {
    const proj = project.value
    return unique(proj.contents?.map(cont => {
        return cont.type
    }))
})

const typeCountX = ((type: string): string => {
    const proj = project.value
    const count = proj.contents?.filter(x => x.type == type).length
    if(count > 1) {
        return 'x' + count
    }
    return ''
})

const sizeClass = computed(() => {
    if(props.size == 's') {
        return "wh-20 fs-06"
    }
    return "wh-25"
})

const fontSizeClass = computed(() => {
    if (props.size == 's') {
        return "fs-06"
    }
    return ""
})

const badgeClass = (type: string) => {
    if (type == 'post') {
        return 'badge-primary'
    } else if (type == 'video') {
        return 'badge-secondary'
    } else if (type == 'story') {
        return 'badge-gray'
    }
    return ""
}

</script>
