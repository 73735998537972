import { ref } from 'vue';
import { createBrand } from "@/services/api/http";
import numbro from 'numbro';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Brand from '@/models/Brand';
import { useSupported } from '@vueuse/core';
import { useModal } from 'vue-final-modal'
import ProjectInfoModal from '@/components/ProjectInfoModal.vue';
import Project from '@/models/Project';

dayjs.extend(utc);
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(relativeTime)

export const useProjectModal = () => {
    const projectModal = useModal({
        component: ProjectInfoModal,
        attrs: {
            onClose() {
                projectModal.close()
            },
        },
    })

    const openProjectModal = (project: Project) => {
        projectModal.patchOptions({
            attrs: {
                project: project,
            }
        })
        projectModal.open()
    }

    return openProjectModal
}

export const clearNullValue = (object: Object): Object => {
    const cleanedObj = Object.entries(object)
    .filter(([key, value]) => value !== null && value !== '')  // Remove keys with null values
    .reduce((acc, [key, value]) => {
        acc[key] = value;  // Add the non-null key-value pair to the accumulator
        return acc;
    }, {});

    return cleanedObj
}

export const unique = <T, K extends keyof T>(array: T[], key?: K): T[] => {
    if(key) {
        // const uniq = Array.from(new Set(array.map(v => {
        //     return v[key]
        // })))
        const keys = array?.map(v => {
            return v[key]
        }) ?? []
        return array?.filter((item, index) => {
            return !keys.includes(item[key], index + 1)
        }) ?? []
    }
    return Array.from(new Set(array));
}

export const isNotifiicationSupported = useSupported(() => {
    if (!window || !('Notification' in window))
        return false
    // https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructor/29895431
    // https://issues.chromium.org/issues/40415865
    try {
        if (Notification.permission == 'granted') {
            return true
        }
        // eslint-disable-next-line no-new
        new Notification('')
    }
    catch (e) {
        return false
    }
    return true
})

export const useDateTextColor = (value: any): string => {
    if(!dayjs(value).isValid()) {
        return "text-dark"
    }
    const date = dayjs.utc(value)
    if(dayjs().subtract(3, 'day').isAfter(date)) {
        return "text-danger"
    } else if(dayjs().isAfter(date)) {
        return "text-warning"
    }
    return "text-dark"
}

export const useDayAgo = (value: any): string => {
    if(!dayjs(value).isValid()) {
        return ""
    }
    return dayjs.utc(value).fromNow();

}

export const useMoney = (value: any, decimal_point: boolean = true): string => {
    if (Number.isInteger(value) && (value === 0 || value === '0')) {
        if (decimal_point) {
            return '0.00';
        }
        return '0'
    }
    if (!value) {
        return '0.00';
    }
    return numbro(value).format({ thousandSeparated: true, mantissa: decimal_point ? 2 : 0 });
}

export const useAutoNumber = (value: any): string => {
    if (Number.isInteger(value) && (value === 0 || value === '0')) {
        return '0'
    }
    if (!value) {
        return '0';
    }
    const val = numbro(value).value()
    if(val == parseInt(value)) {
        return numbro(value).format({ thousandSeparated: true, mantissa: 0 });
    }
    return numbro(value).format({ thousandSeparated: true, mantissa: 2 });
}

export const useIsToday = (value: any): boolean => {
    if(!dayjs(value).isValid()) {
        return false
    }
    return dayjs(value).isToday()
}

export const useIsPast = (value: any): boolean => {
    if(!dayjs(value).isValid()) {
        return false
    }
    return dayjs(value).isBefore(dayjs())
}

export const useIsFuture = (value: any): boolean => {
    if(!dayjs(value).isValid()) {
        return false
    }
    return dayjs(value).isAfter(dayjs())
}

export const useDate = (value: any, format: string = "DD/MM/YYYY"): string => {
    if(!dayjs(value).isValid()) {
        return value || ""
    }
    const date = dayjs.utc(value)
    return date.format(format)
}
export const useShortDate = (value: any): string => {
    if (!dayjs(value).isValid()) {
        return value || ""
    }
    const date = dayjs.utc(value)
    const format = "DD MMM YY"
    return date.format(format)
}
export const useDateTime = (value: any, format: string = "DD MMMM YY, HH:mm"): string => {
    const date = dayjs.utc(value)
    return date.local().format(format)
}
export const useShortDateTime = (value: any, format: string = "DD/MM/YY, HH:mm"): string => {
    const date = dayjs.utc(value)
    return date.local().format(format)
}
export const useDateTimeDefault = (value: any): string => {
    const date = dayjs.utc(value)
    return date.local().format('YYYY-MM-DD, HH:mm')
}
export const useTime = (value: any, format: string = "HH:mm"): string => {
    if(!value) {
        return "00:00"
    }
    const date = dayjs(value, format)
    return date.format(format)
}

type Time = {
    hours: number;
    minutes: number;
}

export const useTimePreview = (time: Time | undefined) => {
    const hours = time?.hours
    const minutes = time?.minutes
    if(hours && minutes) {
        return dayjs().hour(hours).minute(minutes).format('HH:mm')
    }

    return dayjs().startOf('day').format('HH:mm')
}

export const useDateRangePreview = (start: any, end: any): string => {
    const startDay = dayjs(start)
    const endDay = dayjs(end)
    if(startDay.isSame(endDay, 'month') && startDay.isSame(endDay, 'year')) {
        return `${dayjs(start).format('DD')} - ${dayjs(end).format('DD MMMM YY')}`
    }
    else if (startDay.isSame(endDay, 'year')) {
        return `${dayjs(start).format('DD MMMM')} - ${dayjs(end).format('DD MMMM YY')}`
    }

    return `${dayjs(start).format('DD MMMM YY')} - ${dayjs(end).format('DD MMMM YY')}`
}

export const useOpenNewTab = (value: string): void => {
    window.open(value, '_blank')
}

export const useReplaceURLs = (message: string): string => {
    if (!message) return "";

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
        }
        return '<a class="text-decoration-underline" href="' + hyperlink + '" target="_blank">' + url + '</a>'
    });
}

export const useTextEllipsis = (value: string): string => {
    if(!value) {
        return ""
    }

    const length = value.length
    if(length > 100) {
        return value.substring(0, 100) + " ..."
    }
    return value
}

const DIRECTORY_SEPARATOR = '/'
export const useJoinPaths = (base: string, ...paths: string[]): string => {
    const addition: string[] = []
    paths.forEach( (path) => {
        if(path && path != '') {
            addition.push(path.replace(/^(\/)/, ''))
        }
    })

    return base.replace(/(\/)$/, '').concat(addition.join(''))
}

export const useAddNewBrand = () => {
    const loading = ref(false)
    const data = ref<Brand | null>()
    const success = ref(false)
    const execute = async (title: string) => {
        success.value = false
        data.value = null
        loading.value = true
        return createBrand(title).then( (brand: Brand) => {
            data.value = brand
            success.value = true
            return brand
        }).catch( () => {
            success.value = false
        }).finally( () => {
            loading.value = false

        })
    }

    return {
        execute,
        loading,
        success,
        data
    }
}
