<template>
    <div v-if="mode == BadgeMode.Full">
        <span class="badge badge-primary badge-chip mt-3 mb-3 ms-2 position-relative" :class="colorClass">
            <img :src="ContentHelper.pageImageUrl(content.page) " width="96" height="96">
            <span class="text-capitalize">{{ title }}</span>
        </span>
    </div>
    <div v-else-if="mode == BadgeMode.Medium">
        <div class="d-flex align-items-center column-gap-1">
            <div class="avatar avatar-xs">
                <img alt="avatar" :src="ContentHelper.pageImageUrl(content.page)" class="rounded-circle">
            </div>
            <div v-for="platform in content?.platforms" class="avatar avatar-xs avatar-dark">
                <img alt="avatar" :src="ContentHelper.platformImageUrl(platform)" class="rounded-circle">
            </div>
            <div class="avatar avatar-xs" :class="typeClass">
                <span class="avatar-title rounded-circle text-capitalize">{{ content.type }}</span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="d-flex align-items-center column-gap-1">
            <div class="avatar avatar-sm wh-25">
                <img alt="avatar" :src="ContentHelper.pageImageUrl(content.page) " class="rounded-circle">
            </div>
            <div v-for="platform in content?.platforms" class="avatar avatar-sm wh-25 avatar-dark">
                <img alt="avatar" :src="ContentHelper.platformImageUrl(platform)" class="rounded-circle">
            </div>
            <div class="avatar avatar-sm wh-25" :class="typeClass">
                <span class="avatar-title rounded-circle">{{ shortTitleType }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Content, { ContentHelper } from '@/models/Content';
import { computed, toRef } from 'vue';
import { BadgeMode } from '@/models/Content';
import type { PropType } from 'vue'
import Page from '@/models/Page';

export interface Props {
    content: Content
    mode?: BadgeMode
}

const props = withDefaults(defineProps<Props>(), {
    mode: BadgeMode.Full
})

// const props = defineProps({
//     content: Object as PropType<Content>,
//     mode: BadgeMode
// })

// const props = defineProps<{
//     content: Content,
//     mode: BadgeMode
// }>()


const content = toRef(props, 'content')

const title = computed(() => {
    return content.value.platforms.join(', ') + ' ' + content.value.type
})

const colorClass = computed(() => {
    // if(content.value.platform == 'facebook') {
    //     return 'bg-facebook'
    // } else if (content.value.platform == 'instagram') {
    //     return 'bg-purple-red'
    // } else if (content.value.platform == 'instagram') {
    //     return 'bg-black text-white'
    // }
    return ""
})

// const shortTitlePlatform = computed(() => {
//     if (content.value.platform == 'facebook') {
//         return 'FB'
//     } else if (content.value.platform == 'instagram') {
//         return 'IG'
//     } else if (content.value.platform == 'instagram') {
//         return 'TT'
//     }
//     return ""
// })

const shortTitleType = computed(() => {
    if (content.value.type == 'post') {
        return 'P'
    } else if (content.value.type == 'video') {
        return 'V'
    } else if (content.value.type == 'story') {
        return 'S'
    }
    return ""
})

const typeClass = computed(() => {
    if (content.value.type == 'post') {
        return 'avatar-primary'
    } else if (content.value.type == 'video') {
        return 'avatar-danger'
    } else if (content.value.type == 'story') {
        return 'avatar-info'
    }
    return ""
})

</script>
