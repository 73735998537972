<template>
    <div v-if="date">
        <span class="border p-2 text-nowrap" :class="`${colorClass} ${borderClass}`">{{ title }}</span>
        <span v-if="date" class="border p-2" :class="`${textClass} ${borderClass}`">{{ formattedDate }}</span>
    </div>
    <div v-else class="d-flex">
        <div v-if="size == 's'" class="badge badge-classic" :class="ProjectHelper.statusBadgeClass(project)">{{ title }}</div>
        <div v-else class="border p-2" :class="`${colorClass} ${borderClass}`">
            {{ title }}
            <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin ms-1">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
import Content from '@/models/Content';
import { computed, toRef } from 'vue';
import Project, { ProjectHelper } from '@/models/Project';
import { ProjectStatus } from '@/models/Project';
import type { PropType } from 'vue'
import dayjs from 'dayjs';
import { useDate } from '@/composables/utility';

export interface Props {
    content: Content,
}


// const props = defineProps({
//     content: Object as PropType<Content>,
//     mode: BadgeMode
// })

const props = defineProps<{
    project: Project,
    date?: Boolean,
    size?: string,
    loading?: boolean,
}>()


const project = toRef(props, 'project')

const title = computed((): string => {
    return ProjectHelper.statusTitle(project.value)
})

const colorClass = computed((): string => {
    return ProjectHelper.statusColorClass(project.value)
})

const borderClass = computed((): string => {
    return ProjectHelper.statusBorderClass(project.value)
})

const textClass = computed((): string => {
    return ProjectHelper.statusTextClass(project.value)
})

const formattedDate = computed((): string => {
    switch (project.value.status) {
        case ProjectStatus.Confirmed: {
            return useDate(project.value.date)
        }
        case ProjectStatus.Shooting: {
            return useDate(project.value.shooting_date)
        }
        case ProjectStatus.Draft: {
            return useDate(project.value.draft_date)
        }
        case ProjectStatus.Feedback: {
            return useDate(project.value.post_date)
        }
        case ProjectStatus.Posted: {
            return useDate(project.value.post_date)
        }
        case ProjectStatus.Waiting: {
            return "-"
        }
        case ProjectStatus.Hold: {
            return "-"
        }
        case ProjectStatus.Canceled: {
            return "-"
        }
    }
    return "ไม่ระบุ"
})

</script>
