<template>
    <VueFinalModal class="modal-container" content-class="modal-content" :click-to-close="!loading" :esc-to-close="!loading">
        <div class="modal-header">
            <div class="text-dark">Flow Account Document</div>
            <div class="btn-close cursor-pointer" @click="$emit('close')"></div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-12 col-sm-8">
                    <label for="" class="required text-dark fw-bold me-3">เลขที่เอกสาร</label>
                    <div class="d-flex">
                        <input type="text" :disabled="isEdit" class="form-control text-dark" v-model="document_number" placeholder="เรดไพรซ์ ดิจิตอล">
                    </div>
                </div>
                <div class="form-group col-12 col-sm-4">
                    <label for="" class="text-dark fw-bold me-3">หมายเหตุ</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="note" placeholder="">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button type="button" class="btn btn-light" @click="$emit('close')">ยกเลิก</button>
            <LoadingIndicatorButton :loading="loading" class="btn btn-primary" @click="submit">ยืนยัน</LoadingIndicatorButton>
        </div>
    </VueFinalModal>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, getCurrentInstance, toRef } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import FlowAccountDocument from "@/models/FlowAccountDocument"
import { addFlowAccountDocument, updateFlowAccountDocument } from '@/services/api/http';
import _get from 'just-safe-get';
import { useForm } from 'vee-validate';
import z, { nullable, number } from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useToast } from 'vue-toastification';
import LoadingIndicatorButton from './LoadingIndicatorButton.vue';

const props = defineProps<{
    billingNumber: string,
    document?: FlowAccountDocument,
}>()

const emit = defineEmits<{
    (e: 'updated', data: FlowAccountDocument): void
    (e: 'close'): void
    (e: 'cancel'): void
}>()

const toast = useToast()
const document = toRef(props, 'document')
const isEdit = document.value ? true : false

const { errors, defineField, handleSubmit, setValues } = useForm({
    initialValues: {
        document_number: document.value?.document_number,
        note: document.value?.note,
    },
    validationSchema: toTypedSchema(
        z.object({
            document_number: isEdit ? z.string().nullish() : z.string().min(1),
            note: z.string().nullish(),
        })
    ),
})


const [document_number] = defineField('document_number')
const [note] = defineField('note')

const loading = ref(false)
const submit = handleSubmit(async (values) => {
    loading.value = true
    try {
        if (isEdit && document.value) {
            const data = await updateFlowAccountDocument(document.value, {
                note: values.note,
            })

            emit('updated', data)
            toast.success("อัพเดตจ๊ะ", {
                timeout: 2000
            });
        } else {
            const data = await addFlowAccountDocument(props.billingNumber, {
                document_number: values.document_number,
                note: values.note,
            })
            emit('updated', data)
            toast.success("เพิ่มละจ้า", {
                timeout: 2000
            });
        }
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {
        loading.value = false
    }
}, async ({ values, errors, results }) => {
    toast.error(JSON.stringify(errors))
    toast.error("กรุณากรอกข้อมูลให้ครบ")
})


const init = async function () {

}

onMounted(() => {
    init()
})

</script>
<style lang="scss"></style>
