import { DirectiveBinding, ObjectDirective } from 'vue'

type FocusableElement = HTMLInputElement | HTMLTextAreaElement
type NotificationCallback = (isNowFocused: boolean) => void
type GenericEventHandler = () => void
interface ExtendedDirective extends ObjectDirective {
    fixedClasses: string[]
}

const vExtensionIcon = {
    mounted(element: FocusableElement, binding: DirectiveBinding) {
        const thisDirective = binding.dir as ExtendedDirective
        thisDirective.fixedClasses = [...element.classList]
        const filename = binding.value as string
        const extension = filename.split('.').pop()?.toLowerCase();
        let classes = [] as string[]
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                classes = ["bi","bi-file-earmark-image"]
                break;
            case 'zip':
                classes = ["bi","bi-file-earmark-zip"]
                break;
            case 'pdf':
                classes = ["bi","bi-file-earmark-pdf"]
                break;
            default: {
                classes = ["bi", `bi-filetype-${extension}`]
            }
        }
        element.classList.add(...classes)
        // const callback = (binding.value as NotificationCallback)
        // const thisDirective = binding.dir as ExtendedDirective
        // thisDirective.handleFocus = () => { callback(true) }
        // thisDirective.handleBlur = () => { callback(false) }
        // element.addEventListener('focus', thisDirective.handleFocus)
        // element.addEventListener('blur', thisDirective.handleBlur)
    },
    updated(element: HTMLElement, binding: DirectiveBinding) {
        const thisDirective = binding.dir as ExtendedDirective
        const filename = binding.value as string
        const extension = filename.split('.').pop();
        let className = ""
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                className = "bi-file-earmark-image"
                break;
            case 'zip':
                className = "bi-file-earmark-zip"
                break;
            case 'pdf':
                className = "bi-file-earmark-pdf"
                break;
            default: {
                className = `bi-filetype-${extension}`
            }
        }
        element.className = ""
        element.classList.add(className)
        element.classList.add(...thisDirective.fixedClasses)
    }
}

export default vExtensionIcon
