<template>
    <VueFinalModal class="modal-container" content-transition="vfm-fade" overlay-transition="vfm-fade" content-class="position-absolute inset-0" @update:model-value="val => emit('update:modelValue', val)">
        <div class="position-absolute inset-0 h-100 overflow-auto" @click.self="() => emit('update:modelValue', false)">
            <div class="modal-content project mx-auto my-5">
                <div class="modal-header d-block pb-1">
                    <div class="d-flex">
                        <div v-if="project?.post_date" class="fw-normal badge badge-success me-1">{{ useShortDate(project?.post_date) }}</div>
                        <div v-else class="fw-normal badge badge-success me-1">ยังไม่มีวันโพส</div>
                        <div class="badge badge-dark badge-classic me-1" v-for="scope in project?.package_scopes">{{ scope.title }}</div>
                        <ProjectContentBadge v-if="project" :project="project" size="m"></ProjectContentBadge>
                        <div class="btn-close cursor-pointer" @click="$emit('close')"></div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="mt-2">
                            <h5 class="modal-title">{{ project?.title }} <span @click="copyProjectUrl" class="px-2 py-1 border rounded bg-warning-subtle fs-07 cursor-pointer">Copy link <i class="bi bi-copy"></i></span></h5>
                            <div class="d-flex align-items-center column-gap-1">
                                <ProjectStatusBadge v-if="project" :project="project" :date="false"></ProjectStatusBadge>
                                <div class="text-muted fs-08 fw-normal">
                                    {{ project?.number }}
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <router-link :to="{ name: 'project.view', params: { number: project?.number } }" class="btn btn-sm btn-outline-primary px-2"><i class="bi bi-arrows-fullscreen"></i> &nbsp;View</router-link>
                            <router-link :to="{ name: 'project.edit', params: { number: project?.number } }" class="ms-2 btn btn-sm btn-outline-dark px-2"><i class="bi bi-pencil-square"></i> แก้ไข</router-link>
                        </div>
                    </div>
                    <div class="d-flex column-gap-1 justify-content-between mt-2">
                        <div class="border rounded text-bg-primary-subtle px-2">{{ project?.client?.name ?? 'ยังไม่ใส่ลูกค้า' }}</div>
                        <div class="d-flex column-gap-1 justify-content-end align-items-center">
                            <div class="border rounded text-bg-info-subtle px-2" v-for="category in project?.categories">{{ category.title }}</div>
                            <div class="border rounded text-bg-success-subtle px-2" v-for="brand in project?.brands">{{ brand.title }}</div>
                            <div class="border rounded text-bg-info-subtle px-2" v-if="!project?.categories || project?.categories.length <= 0">ใส่ Category ด้วย</div>
                            <div class="border rounded text-bg-success-subtle px-2" v-if="!project?.brands || project?.brands.length <= 0">ใส่ Brand ด้วย</div>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="text-dark">
                        <div class="row mt-1">
                            <div class="col-12 col-sm-6">
                                <div class="fs-6 mb-2 text-decoration-underline">รายละเอียดงานเพิ่มเติม</div>
                                <div class="white-space-pre-wrap text-break for-read" style="min-height: 100px;" v-html="useReplaceURLs(project?.scope_of_work || '')">

                                </div>
                            </div>
                            <div class="col-12 col-sm-3 mt-3 mt-sm-0">
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex column-gap-2 mb-1">
                                            <div class="me-3 fw-bold text-dark">Timelines</div>
                                        </div>
                                        <ProjectTimelines :project-number="project?.number || ''"></ProjectTimelines>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex column-gap-2 mb-1">
                                            <div class="me-3 fw-bold text-dark">Contents</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 border rounded px-2 py-2 mb-2 bg-white" v-for="(content, index) in contents">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex mb-2 column-gap-2">
                                                        <ContentBadge :content="content" :mode="BadgeMode.Small"></ContentBadge>
                                                    </div>
                                                    <div class="">
                                                        <ContentStatusBadge :content="content" :date="false"></ContentStatusBadge>
                                                    </div>
                                                </div>
                                                <div class="d-flex column-gap-1 row-gap-1 flex-wrap">
                                                    <div>
                                                        <span class="badge badge-secondary">
                                                            <i class="bi bi-person-circle"></i>
                                                            {{ content.shooting_user?.name }}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span class="badge badge-info">
                                                            <i class="bi bi-person-circle"></i>
                                                            {{ content.draft_user?.name }}
                                                        </span>
                                                    </div>
                                                    <div class="ms-auto fs-08 fw-normal">
                                                        <router-link :to="{ name: 'content.view', params: { number: content.number } }" target="_blank"> {{ content.number }} <i class="bi bi-arrow-up-right"></i></router-link>
                                                    </div>
                                                </div>
                                                <div class="fs-08 mt-4 mb-1">ลิ้งโพสงาน</div>
                                                <div class="d-flex justify-content-between">
                                                    <div class="">
                                                        <a :href="content.assets_url" class="badge outline-badge-success">
                                                            <i class="bi bi-google"></i> Drive <i v-if="content.assets_url" class="bi bi-check-lg"></i><i v-else class="bi bi-x-lg"></i>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex justify-content-start row-gap-1 flex-wrap">
                                                        <div v-for="(platform, index) in content.content_platforms">
                                                            <a :href="platform.content_url" target="_blank" class="badge me-1 text-capitalize" :class="{
                                                                    'outline-badge-primary': platform.platform == 'facebook',
                                                                    'outline-badge-instagram': platform.platform == 'instagram',
                                                                    'outline-badge-dark': platform.platform == 'tiktok',
                                                                    'outline-badge-gray': platform.platform == 'blog'
                                                                }">
                                                                {{platform.platform}} <i v-if="platform.content_url" class="bi bi-check-lg"></i><i v-else class="bi bi-x-lg"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="fs-6 mb-2 text-decoration-underline">บรีฟ, ไฟล์ต่างๆ </div>
                                            <ProjectFiles :project-number="project?.number || ''">

                                            </ProjectFiles>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3 border border-end-0 border-start-0 border-bottom-0 border-secondary-subtle">
                            <div class="col-12 col-sm">
                                <div class="fs-6 mt-3 mb-2 text-decoration-underline">Activity </div>
                                <ProjectComments :project-number="project?.number || ''">

                                </ProjectComments>
                            </div>
                            <div v-if="financeAuthorized" class="col-12 col-sm-4 bg-white">
                                <ProjectPayments class="p-2" :project-number="projectNumber" mode="stack">
                                </ProjectPayments>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">

                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script setup lang="ts">
import { ref, computed, toRef, onMounted } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { AxiosError } from 'axios';
import Project from '@/models/Project';
import Content, { ContentHelper, BadgeMode } from '@/models/Content';
import Timeline, { TimelineHelper } from '@/models/Timeline';
import ContentStatusBadge from '@/components/ContentStatusBadge.vue';
import ProjectStatusBadge from '@/components/ProjectStatusBadge.vue';
import ProjectContentBadge from '@/components/ProjectContentBadge.vue';
import { useShortDate } from '@/composables/utility';
import { useDateTime, useOpenNewTab, useReplaceURLs, useTime } from '@/composables/utility';
import { getProjectFiles, postProjectComment, projectRemoveFile, getProjectContents } from "@/services/api/http";
import ProjectTimelines from '@/components/ProjectTimelines.vue';
import ProjectFiles from './ProjectFiles.vue';
import ProjectComments from './ProjectComments.vue';
import ProjectPayments from '@/components/ProjectPayments.vue';
import ContentBadge from './ContentBadge.vue';
import { useClipboard } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia';

const props = defineProps<{
    project?: Project
}>()

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: boolean): void
    (e: 'close'): void
}>()

const project = toRef(props, 'project')
const contents = ref<Content[]>([])
const projectNumber = props.project?.number || ''

const userStore = useUserStore()
const { user, financeAuthorized } = storeToRefs(userStore)
const router = useRouter()
const toast = useToast()
const projectUrl = computed(() => {
    const number = project.value?.number
    const { href } = router.resolve({ name: 'project.view', params: { number: projectNumber } })
    const baseUrl = window.location.origin;
    return `${baseUrl}${href}`
})

const { text, copy, copied, isSupported } = useClipboard({

 })
const copyProjectUrl = () => {
    copy(projectUrl.value)
    toast.success("Copied")
}

async function getContents() {
    try {
        const number = projectNumber
        const data = await getProjectContents(number)
        contents.value = data
    }
    catch (err: any) {
        console.log(err)
        if (err instanceof AxiosError) {
            if (err.response?.status == 404) {
                // notFound.value = true
            }
        }
    }
    finally {

    }
}

async function init() {
    if(project.value && project.value.contents) {
        contents.value = project.value?.contents
    } else {
        getContents()
    }
}

onMounted( () => {
    init()
})

</script>
<style lang="scss">
.btn-close {
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    &:hover {
        background-color: #091e420f;
        color: #44546f;
        text-decoration: none;
    }
}
.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>

