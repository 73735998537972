import { createApp } from 'vue'
import { createHead } from "@vueuse/head";
import { createPinia } from 'pinia'


// bootstrap
import * as bootstrap from "bootstrap";
import _get from 'just-safe-get';
window.bootstrap = bootstrap;
// perfect scrollbar
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
// smooth scroll
import { registerScrollSpy } from "vue3-scroll-spy/dist/index";
import "bootstrap-icons/font/bootstrap-icons.min.css";

import router from './router'
import "@/assets/cork/sass/app.scss";
import '@/assets/cork/sass/forms/custom-flatpickr.css';
import '@/assets/cork/sass/widgets/widgets.scss';
import '@/assets/cork/sass/apps/chat.scss';
import '@/assets/cork/sass/apps/mailbox.scss';
import '@/assets/cork/sass/elements/breadcrumb.scss';
import "@/assets/cork/sass/apps/scrumboard.scss";
import App from './App.vue'

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueUploadComponent from 'vue-upload-component'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueApexCharts from "vue3-apexcharts";
import "@jobinsjp/vue3-datatable/dist/style.css"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import { createVfm } from 'vue-final-modal'
import money from 'v-money3'
import VueAwesomePaginate from "vue-awesome-paginate";
import 'vue-final-modal/style.css'
import LoadingIndicatorButton from "@/components/LoadingIndicatorButton.vue"
import { createPersistedState } from 'pinia-plugin-persistedstate'
import "vue-awesome-paginate/dist/style.css";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '2df875983e74190402c8af7b800976cb',
  plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: '2df875983e74190402c8af7b800976cb' })
const bugsnagVue = Bugsnag.getPlugin('vue')

import LoadingOverlay from "@/libs/loading-overlay/js/Component.vue"
import "@/libs/loading-overlay/css/index.css"

import './styles/app.scss'
import './styles/cork.scss'

import './firebase'
import { Messaging } from 'firebase/messaging';
import VueEasyLightbox from 'vue-easy-lightbox'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

const app = createApp(App)

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        _get: typeof _get,
        $messaging: Messaging
    }
}
app.config.globalProperties._get = _get
// app.config.globalProperties.$messaging = firebaseMessaging

const pinia = createPinia()
pinia.use(createPersistedState({
    storage: localStorage,
    auto: true,
}))

const head = createHead();
const vfm = createVfm()

app.use(pinia)
app.use(router)
app.use(head)
registerScrollSpy(app, { offset: 118 });
app.use(PerfectScrollbar)
app.use(Toast, {

} as PluginOptions);
app.use(VueApexCharts);
app.use(vfm)
app.use(money)
app.use(VueAwesomePaginate)
app.use(bugsnagVue!)
app.use(VueEasyLightbox)
app.use(FloatingVue)

app.component('file-upload', VueUploadComponent);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('VueDatePicker', VueDatePicker);
app.component('flatPickr', flatPickr);
app.component('Multiselect', Multiselect);
app.component('VueMultiselect', VueMultiselect);
app.component('LoadingOverlay', LoadingOverlay);

app.component('LoadingIndicatorButton', LoadingIndicatorButton);

import { maskito } from '@maskito/vue';
app.directive('maskito', maskito)

app.mount('#app')

// getToken(messaging, {vapidKey: "BApWnBzaDvqeO2_NmrYdlpt8CDrPlIGgRSRhNbUSGTf5fIKpOaLKsabUl4bkeclzC0hIYDD9gsdV8QhSZrHuImY"}
