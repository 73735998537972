import { time } from "node:console";
import Project from "./Project";
import TimelineAttachment from "./TimelineAttachment";
import User from "./User";

export default interface Timeline {
    id?: number;
    project_id?: number;
    action: string;
    date: string;
    is_complete: boolean;
    time?: string;
    user?: User;
    note?: string;
    project?: Project;
    isRecentlyCreated?: boolean;
    timeline_attachments?: TimelineAttachment[];
    timeline_related_users?: User[];
    has_video?: boolean;
}

export enum TimelineAction {
    Confirmed = "confirmed",
    Idea = "idea",
    Delivered = "delivered",
    Shooting = "shooting",
    Draft = "draft",
    SendDraft = "send_draft",
    Feedback = "feedback",
    ApproveDraft = "approve_draft",
    Post = "post",
    Waiting = "waiting",
    Hold = "hold",
    Canceled = "canceled",
}

export class TimelineHelper {

    static actionToTitle(action: string): string {
        switch (action) {
            case "idea": {
                return "ส่งไอเดีย"
            }
            case "delivered": {
                return "รอรับของ"
            }
            case "shooting": {
                return "ลงพื้นที่"
            }
            case "draft": {
                return "ทำดราฟ"
            }
            case "send_draft": {
                return "ส่งดราฟ"
            }
            case "feedback": {
                return "ฟีดแบคงาน"
            }
            case "approve_draft": {
                return "Approve draft"
            }
            case "post": {
                return "โพสงาน"
            }
        }
        return "อื่นๆ"
    }

    static actionTitle(timeline?: Timeline): string {
        if(!timeline) {
            return ""
        }
        return TimelineHelper.actionToTitle(timeline.action)
    }

    static isTimeExists(timeline: Timeline): boolean {
        if(timeline.time && timeline.time != '00:00' && timeline.time != '00:00:00') {
            return true
        }
        return false
    }

    static bgColorOfAction(action: string): string {
        switch (action) {
            case TimelineAction.Confirmed: {
                return "text-bg-dark-subtle"
            }
            case TimelineAction.Idea: {
                return "text-bg-dark"
            }
            case TimelineAction.Delivered: {
                return "text-bg-gray"
            }
            case TimelineAction.Shooting: {
                return "bg-secondary"
            }
            case TimelineAction.Draft: {
                return "bg-info"
            }
            case TimelineAction.SendDraft: {
                return "bg-dark-pink"
            }
            case TimelineAction.Feedback: {
                return "bg-warning"
            }
            case TimelineAction.ApproveDraft: {
                return "bg-success-subtle"
            }
            case TimelineAction.Post: {
                return "bg-success"
            }
            case TimelineAction.Waiting: {
                return "bg-white"
            }
            case TimelineAction.Hold: {
                return "bg-default"
            }
            case TimelineAction.Canceled: {
                return "bg-danger"
            }
        }
        return ""
    }
}
