<template>
    <div class="bg-white">
        <div class="d-flex column-gap-2 align-items-center">
            <div class="d-flex column-gap-1">
                <div>การเก็บเงิน ตั่งๆ </div>
                <div v-if="billingNumber">
                    <router-link target="_blank" class="badge badge-dark" :to="{ name: 'billing.view', params: { number: billingNumber } }">{{ billingNumber }}</router-link>
                </div>
            </div>
            <div class="d-flex column-gap-1">
                <div v-if="billingNumber" @click="clickAdd" class="btn btn-success-subtle px-2 py-0"> เพิ่มเอกสาร +</div>
                <LoadingIndicatorButton v-else @click="clickCreateBilling" class="btn btn-success px-3 py-1" :loading="isCreatingBilling">
                    เริ่มเอกสารเก็บเงิน
                </LoadingIndicatorButton>
            </div>
        </div>
        <div class="mt-4" v-if="billingNumber">
            <div class="row">
                <div class="col-12 col-sm">
                    <div v-if="billingNumber && sortedDocuments && sortedDocuments.length > 0" class=" border rounded p-2 mb-1" v-for="item in sortedDocuments">
                        <div class="media" :data-document="item.id">
                            <div class="media-aside align-self-start" :data-document="item.id">
                                <i class="bi bi-file-earmark" style="font-size: 20px;"></i>
                            </div>
                            <div class="media-body" :data-document="item.id">
                                <div class="d-flex justify-content-between align-items-end">
                                    <div class="fs-12"><span class="media-title"> {{ item.document_number }}</span></div>
                                    <div class="text-muted">
                                        {{ item.note }}
                                        <a href="#" @click.prevent="clickEdit(item)"><i class="bi bi-pencil-square"></i></a>
                                        <a class="ms-2" href="#" @click.prevent="deleteThis(item)"><i class="bi bi-trash text-danger"></i></a>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between" v-if="false">
                                    <div>
                                        <span class="badge badge-primary-subtle">ดำเนินการแล้ว</span>
                                    </div>
                                    <div class="fs-11"> 10,000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class=" border rounded p-2 mb-1">
                        ยังไม่มีเอกสารใดๆ
                    </div>
                </div>
                <div class="col-12" :class="[mode == 'normal' ? 'col-sm' : '']">
                    <div class="text-end">มูลค่า Project ไม่รวมหัก ไม่รวม VAT</div>
                    <div @click="showValueModal = true" class="border-bottom rounded p-2 hover-primary-subtle cursor-pointer">
                        <div class="fs-4 text-end text-primary">
                            {{ useMoney(projectValue) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <VueFinalModal v-model="showValueModal" :overlay-transition="ValueModalOptions.overlayTransition" :content-transition="ValueModalOptions.contentTransition" class="modal-container" content-class="modal-content" :click-to-close="!valueModalSubmitting" :esc-to-close="!valueModalSubmitting">
            <div class="modal-header">
                <h5 class="modal-title">แก้ไขมูลค่า Project</h5>
                <div class="btn-close cursor-pointer" @click="showValueModal = false"></div>
            </div>
            <div class="modal-body">
                <div class="text-dark">
                    <label for="" class="form-label me-1">มูลค่า Project ไม่รวมหัก ไม่รวม VAT</label>
                    <input type="text" class="text-end form-control" v-maskito="decimalOptions" placeholder="" v-model="projectValueInput">
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <button type="button" class="btn btn-light" @click="showValueModal = false">ยกเลิก</button>
                <LoadingIndicatorButton class="btn btn-primary ms-3" :loading="valueModalSubmitting" @click="postUpdateProjectValue">
                    ยืนยัน
                </LoadingIndicatorButton>
            </div>
        </VueFinalModal>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, toRef, onMounted } from 'vue'
import Project from '@/models/Project';
import { useModal, VueFinalModal } from 'vue-final-modal'
import FlowAccountFormModal from "./FlowAccountFormModal.vue";
import FlowAccountDocument from "@/models/FlowAccountDocument";
import { deleteFlowAccountDocument, getProjectBilling, updateProjectValue } from '@/services/api/http';
import { useDateTime, useMoney, useReplaceURLs, useJoinPaths } from '@/composables/utility';
import LoadingIndicatorButton from '@/components/LoadingIndicatorButton.vue'
import DialogModal from '@/components/DialogModal.vue'
import vExtensionIcon from '@/directives/extension-icon'
import { getFlowAccountDocuments, createBilling } from "@/services/api/http";
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from '@maskito/kit';
import { useToast } from "vue-toastification"
import { maskitoTransform } from '@maskito/core';

const props = defineProps<{
    projectNumber: string,
    mode: string
}>()

const toast = useToast()


const projectNumber = toRef(props, 'projectNumber')
const displayMode = toRef(props, 'mode')
const billingNumber = ref<string>()
const flowAccountDocuments = ref<FlowAccountDocument[]>([])
const preferredOrder = ["quotation", 'billing', 'invoice']
const sortedDocuments = computed(() => {
    return flowAccountDocuments.value?.sort(function (a, b) {
        return preferredOrder.indexOf(a.type) - preferredOrder.indexOf(b.type);
    }) ?? [];
})

const isCreatingBilling = ref(false)

const clickCreateBilling = async () => {
    try {
        isCreatingBilling.value = true
        const number = projectNumber.value
        const data = await createBilling(number)
        billingNumber.value = data.number
        return data
    }
    catch (err: any) {

    }
    finally {
        isCreatingBilling.value = false
    }
}

const modal = useModal({
    component: FlowAccountFormModal,
    attrs: {
        onClose() {
            modal.close()
        },
        onCancel() {
            modal.close()
        },
        onUpdated(data: FlowAccountDocument) {
            const aDocumentIndex = flowAccountDocuments.value?.findIndex(item => item.id == data.id) ?? -1
            if (aDocumentIndex >= 0) {
                flowAccountDocuments.value?.splice(aDocumentIndex, 1, data)
            } else if (data && data.id) {
                flowAccountDocuments.value?.push(data)
            }
            modal.close()
        },
        billingNumber: billingNumber.value!
    },
})

const clickAdd = () => {
    modal.patchOptions({
        attrs: {
            billingNumber: billingNumber.value!,
            document: undefined
        }
    })
    modal.open()
}

const clickEdit = (document: FlowAccountDocument) => {
    modal.patchOptions({
        attrs: {
            billingNumber: billingNumber.value!,
            document: document
        }
    })
    modal.open()
}

const onLongPress = (document: FlowAccountDocument) => {
    return (e: PointerEvent) => {
        if (document) {
            deleteThis(document)
        }
    }
}

const deleteModal = useModal({
    component: DialogModal,
    attrs: {
        onClose() {
            deleteModal.close()
        },
        onCancel() {
            deleteModal.close()
        },
        onConfirm(data: any) {
            deleteModal.close()
            const document = data as FlowAccountDocument
            deleteDocument(document)
        },
        title: 'ต้องการลบจริงแท้แน่นอนชัวๆนะ',
        submitBtnClass: 'btn-danger'
    },
    slots: {
        default: 'แน่ใจรึป่าว'
    }
})
const deleteThis = (document: FlowAccountDocument) => {
    deleteModal.patchOptions({
        attrs: {
            data: document
        },
        slots: {
            default: document.document_number
        }
    })
    deleteModal.open()
}
const deleteDocument = async (document: FlowAccountDocument) => {
    try {
        const documentId = document.id
        await deleteFlowAccountDocument(document)
        const index = flowAccountDocuments.value?.findIndex(c => c.id == documentId) ?? -1
        if (index >= 0) {
            flowAccountDocuments.value?.splice(index, 1)
        }
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {

    }

}

const projectValue = ref(0)
const projectValueInput = ref("")

const showValueModal = ref(false)
const valueModalSubmitting = ref(false)
const ValueModalOptions = ref({
    teleportTo: 'body',
    modelValue: false,
    displayDirective: 'if' as ("if" | "show" | "visible" | undefined),
    hideOverlay: false,
    overlayTransition: 'vfm-fade',
    contentTransition: 'vfm-fade',
    clickToClose: true,
    escToClose: true,
    background: 'non-interactive' as ("non-interactive" | "interactive" | undefined),
    lockScroll: true,
    reserveScrollBarGap: true,
    swipeToClose: 'none',
})
const decimalOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: 2,
});

const postUpdateProjectValue = async () => {
    const value = maskitoParseNumber(projectValueInput.value, '.')
    valueModalSubmitting.value = true
    try {
        const number = projectNumber.value
        const data = await updateProjectValue(projectNumber.value, value)
        showValueModal.value = false
        projectValue.value = value
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {
        valueModalSubmitting.value = false
    }

}

async function getPaymentData() {
    try {
        const number = projectNumber.value
        const { project, billing }  = await getProjectBilling(number)
        console.log(billing)
        projectValue.value = project.project_value
        if(projectValue.value) {
            projectValueInput.value = maskitoTransform(projectValue.value.toString(), decimalOptions)
        }
        if(billing) {
            console.log('xxxx')
            billingNumber.value = billing.number
            const documents = await getFlowAccountDocuments(billing.number)
            flowAccountDocuments.value = documents ?? []
        }
    }
    catch (err: any) {
        console.log(err)
    }
    finally {

    }
}

async function init() {
    await getPaymentData()
}

onMounted(() => {
    init()
})

</script>
