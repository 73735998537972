<template>
    <div class="">
        <div class="shadow-none rounded position-relative" ref="dropZoneRef">
            <div v-if="isOverDropZone" class="w-100 h-100 bg-white position-absolute opacity-75 d-flex align-items-center justify-content-center z-3">
                <h3>Drop here</h3>
            </div>
            <div class="p-3 pb-0 bg-line rounded-top" v-show="comments.length > 0">
                <div class="row">
                    <div class="col">
                        <template v-for="comment in comments">
                            <div v-if="isMyComment(comment)" class="chat mb-2 me" v-on-long-press="onLongPress" :data-comment="comment.id">
                                <div class="position-relative float-end">
                                    <div class="position-absolute" style="left: -2rem;" v-if="comment.loading">
                                        <div class="spinner-border spinner-border-reverse text-primary align-self-center loader-sm"></div>
                                    </div>
                                    <div v-if="hasMedia(comment)" class="chat-bubble white-space-pre-wrap text-break for-read me" style="max-width: 65%;" :data-comment="comment.id">
                                        <div class="d-inline-flex flex-wrap align-items-start justify-content-evenly" :data-comment="comment.id">
                                            <img v-for="(media, index) in comment.media" @click="showLightbox(index, comment.media)" :src="media.full_url" class="img-thumbnail" :class="[mediaClass(comment.media?.length ?? 0)]" :data-comment="comment.id" />
                                        </div>
                                    </div>
                                    <div v-else class="chat-bubble white-space-pre-wrap text-break for-read me" :data-comment="comment.id" v-html="useReplaceURLs(comment.text)"></div>
                                </div>
                                <div class="text-dark fw-light fs-07 clear-both text-end">{{ useDateTime(comment.created_at) }}</div>
                            </div>
                            <div v-else class="chat mb-2 you">
                                <div class="text-black">{{ comment.user.name }}</div>
                                <div v-if="hasMedia(comment)" class="chat-bubble white-space-pre-wrap text-break for-read you" style="max-width: 65%;" :data-comment="comment.id">
                                    <div class="d-inline-flex flex-wrap align-items-start justify-content-evenly" :data-comment="comment.id">
                                        <img v-for="(media, index) in comment.media" @click="showLightbox(index, comment.media)" :src="media.full_url" class="img-thumbnail" :class="[mediaClass(comment.media?.length ?? 0)]" :data-comment="comment.id" />
                                    </div>
                                </div>
                                <div v-else class="chat-bubble white-space-pre-wrap text-break for-read you" v-html="useReplaceURLs(comment.text)"></div>
                                <div class="text-dark fw-light fs-07 clear-both text-start">{{ useDateTime(comment.created_at) }}</div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="p-3 bg-white border rounded-bottom" :class="{ 'border-top-0': comments.length > 0 }">
                <div class="row">
                    <div class="col-auto d-none d-sm-block">
                        <div class="d-flex flex-column">
                            <div class="avatar avatar-sm align-self-center">
                                <span class="avatar-title rounded-circle">{{ userStore.user?.name.substring(0, 1) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto px-1 d-none d-sm-block">
                        <div @click="open()" class="fs-11 border px-1 rounded cursor-pointer"><i class="bi bi-image"></i></div>
                    </div>
                    <div class="col-12 col-sm">
                        <textarea ref="textarea" v-model="input" class="form-control resize-none" rows="2"></textarea>
                    </div>
                    <div class="col-12 col-sm-auto mt-2 mt-sm-0 d-flex justify-content-between align-items-start">
                        <div class="d-block d-sm-none">
                            <div class="fs-12 border px-2 rounded cursor-pointer"><i class="bi bi-image"></i></div>
                        </div>
                        <LoadingIndicatorButton class="btn btn-primary btn-sm" position="center" @click="postComment" :loading="postCommentLoading">
                            Send
                        </LoadingIndicatorButton>
                    </div>
                </div>
            </div>

        </div>
        <vue-easy-lightbox rotateDisabled moveDisabled pinchDisabled loop :visible="lightBoxVisible" :imgs="lightBoxImages" :index="lightBoxIndex" @hide="onLightboxHide"></vue-easy-lightbox>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useTextareaAutosize } from '@vueuse/core'
import Comment from '@/models/Comment';
import { getProjectComments, postProjectComment, postProjectImageComment, deleteProjectComment } from "@/services/api/http";
import { useUserStore } from '@/stores/user';
import { useDateTime, useOpenNewTab, useReplaceURLs } from '@/composables/utility';
import { useToast } from 'vue-toastification';
import { vOnLongPress } from '@vueuse/components'
import { useFileDialog, useDropZone, useEventListener } from '@vueuse/core'
import DialogModal from '@/components/DialogModal.vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import Media from '@/models/Media';
import VueEasyLightbox from 'vue-easy-lightbox'

const userStore = useUserStore()
const toast = useToast()

const props = defineProps<{
    projectNumber: string
}>()

const comments = ref<Comment[]>([])
const promptComment = ref<string>("")
const { textarea, input } = useTextareaAutosize({
    input: promptComment,
    styleProp: 'minHeight'
})
const hasMedia = (comment: Comment) => {
    if(comment.media && comment.media.length > 0) {
        return true
    }
    return false
}

const mediaClass = (count: number) => {
    switch(count) {
        case 1:
            return 'w-100'
        case 2:
            return 'w-47'
        case 3:
            return 'w-30'
    }
    return ''
}

const lightBoxVisible = ref(false)
const lightBoxImages = ref<string[]>([])
const lightBoxIndex = ref(0)

const showLightbox = (index: number, medias?: Media[]) => {
    if(!medias) {
        return
    }
    lightBoxImages.value = medias.map( (item) => {
        return item.full_url
    })
    lightBoxIndex.value = index
    lightBoxVisible.value = true
}

const onLightboxHide = () => {
    lightBoxVisible.value = false
}

useEventListener(document, 'keydown', (e) => {
    if (e.key == 'Escape' && lightBoxVisible.value) {
        lightBoxVisible.value = false
        e.stopPropagation()
    }
}, true)

const onLongPress = (e: PointerEvent) => {
    const el = e.target as HTMLElement
    const commentId = el.getAttribute('data-comment')
    if (commentId) {
        const comment = comments.value.find(c => c.id == parseInt(commentId))
        if(comment) {
            deleteThisComment(comment)
        }
    }
}

const postCommentLoading = ref<boolean>(false)
const postComment = async () => {
    try {
        if (!promptComment.value || promptComment.value == "") {
            return
        }
        postCommentLoading.value = true
        const data = await postProjectComment(props.projectNumber, promptComment.value)
        comments.value = data
        promptComment.value = ""
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {
        postCommentLoading.value = false
    }
}

const dropZoneRef = ref<HTMLDivElement>()

function onDrop(files: File[] | null) {
    // called when files are dropped on zone
    if(files) {
        handleFiles(files)
    }
}
const { isOverDropZone } = useDropZone(dropZoneRef, {
    onDrop,
    // specify the types of data to be received.

})

const sendImageModal = useModal({
    component: DialogModal,
    attrs: {
        onClose() {
            sendImageModal.close()
        },
        onCancel() {
            sendImageModal.close()
        },
        onConfirm(data: any) {
            sendImageModal.close()
            uploadImageComment()
        },
        title: 'ต้องการส่งรูปนี้แน่ใจนะ',
        submitBtnClass: 'btn-success'
    },
    slots: {
        default: 'แน่ใจรึป่าว'
    }
})

const invalidImages = ref(false)
const { files, open, reset, onChange } = useFileDialog({
    accept: 'image/png, image/jpeg, image/webp', // Set to accept only image files
    multiple: true,
    reset: true,
})

onChange((files) => {
    if(files) {
        const fileArray = Array.from(files)
        handleFiles(fileArray)
    }
})

const handleFiles = (files: File[]) => {
    if (files && files.length > 3) {
        invalidImages.value = false
        toast.warning("ใส่ได้ทีละ 3 รูปนะ", {
            timeout: 2000
        });
    } else if (files && files.length > 0) {
        const imageCount = files.length
        let invalid = false
        for (const file of files) {
            if (file.size > 2 * 1024 * 1024) {
                invalid = invalid || true
                toast.warning("มีรูปที่เกิน 2MB อะเลือกใหม่นะ", {
                    timeout: 2000
                });
            }
        }
        invalidImages.value = invalid
        if (!invalid) {
            sendImageModal.patchOptions({
                slots: {
                    default: `ส่งทั้งหมด ${imageCount} รูป`
                }
            })
            sendImageModal.open()
        }
    } else {

    }
}

const uploading = ref(false)
const uploadImageComment = async () => {
    const formData = new FormData();

    if (files.value && files.value.length > 0) {
        for (let i = 0; i < files.value.length; i++) {
            const file = files.value.item(i)
            if(file) {
                formData.append("images[]", file)
            }
        }
        for(const file of files.value) {

        }
    }
    uploading.value = true
    try {
        const data = await postProjectImageComment(props.projectNumber, formData)
        comments.value = data
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {
        uploading.value = false
    }
}

const deleteModal = useModal({
    component: DialogModal,
    attrs: {
        onClose() {
            deleteModal.close()
        },
        onCancel() {
            deleteModal.close()
        },
        onConfirm(data: any) {
            deleteModal.close()
            const comment = data as Comment
            deleteComment(comment)
        },
        title: 'ต้องการลบ Comment จริงแท้แน่นอนชัวๆนะ',
        submitBtnClass: 'btn-danger'
    },
    slots: {
        default: 'แน่ใจรึป่าว'
    }
})
const deleteThisComment = (comment: Comment) => {
    deleteModal.patchOptions({
        attrs: {
            data: comment
        },
        slots: {
            default: comment.text
        }
    })
    deleteModal.open()
}
const deleteComment = async (comment: Comment) => {
    try {
        const commentId = comment.id
        comment.loading = true
        await deleteProjectComment(props.projectNumber, comment)
        const index = comments.value.findIndex( c => c.id == commentId)
        comments.value.splice(index, 1)
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {

    }

}


const isMyComment = (comment: Comment): boolean => {
    return comment.user_id == userStore.user!.id
}

async function getFiles() {
    try {
        const number = props.projectNumber
        const data = await getProjectComments(number)
        return data
    }
    catch (err: any) {

    }
    finally {

    }
}

async function init() {
    const data = await getFiles()
    comments.value = data ?? []
}

onMounted(() => {
    init()
})

</script>
<style lang="scss">
textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

textarea::-webkit-scrollbar {
    display: none;
}
.resize-none {
    resize: none;
}
</style>
