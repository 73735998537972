<template>
    <div class="d-flex">
        <div class="border p-1" :class="`${colorClass} ${borderClass}`">{{ title }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import type { PropType } from 'vue'
import dayjs from 'dayjs';
import Content, { ContentHelper } from '@/models/Content';

export interface Props {
    content: Content
}


// const props = defineProps({
//     content: Object as PropType<Content>,
//     mode: BadgeMode
// })

const props = defineProps<{
    content: Content
}>()


const content = toRef(props, 'content')

const title = computed((): string => {
    return ContentHelper.statusTitle(content.value)
})

const colorClass = computed((): string => {
    return ContentHelper.statusColorClass(content.value)
})

const borderClass = computed((): string => {
    return ContentHelper.statusBorderClass(content.value)
})

const textClass = computed((): string => {
    return ContentHelper.statusTextClass(content.value)
})

</script>
